import {React, useEffect} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Psychotest from "../components/psychotest"
//import Logo from "../images/psychotest.svg"
//import Background from "../images/bg.svg"
//import Cursor from "../images/smallshroom.svg"
//import Bye from "../images/bye.svg"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title


  useEffect(() => {

    document.getElementById('lang-switch').href= '/pl/psychotest/';
    if(localStorage.getItem("language")==null){
      localStorage.setItem("language", 'EN')
    }
    // for window scrolldown event
    window.onscroll = (()=> {

      let mybtn = document.getElementById("back-to-top");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybtn.style.display = "block";
      } else {
        mybtn.style.display = "none";
      }




      let mainSection = document.querySelectorAll(".secsection")
        let menuSection = document.querySelector('.second-menu').querySelectorAll('li')
      mainSection.forEach((v,i)=> {
        let rect = v.getBoundingClientRect().y
        if(rect < window.innerHeight-200){
          menuSection.forEach(v=> v.classList.remove('actives'))
          menuSection[i].classList.add('actives')
        }
      })

      var elDistanceToTop = window.pageYOffset + document.querySelector('.second-menu').getBoundingClientRect().top
      console.log(elDistanceToTop)
      console.log(window.pageYOffset)
      // console.log(window.pageYOffset)
      if(window.scrollY >= 45){
        document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px';
        document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'fixed'
      
        document.querySelector('.second-menu').style.position = 'fixed'
        document.querySelector('.second-menu').style.width = '100%'
        document.querySelector('.second-menu').style.left = '0'
        document.querySelector('.second-menu').style.top = '65px'
        document.querySelector('.second-menu').style.zIndex = '20'

      }else{document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px'
      document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'relative'
      document.querySelector('.second-menu').style.position = ''
      }

    });

  })


  return (
    <Layout location={location} title={siteTitle}>
    <Seo title="Psychotest" />


    <div style={{marginTop:'30px'}}>

    </div>
    <Psychotest />

    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
