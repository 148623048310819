import React from 'react';


const Psychotest = () => {

let answers = {'1':null,'2':null,'3':null,'4':null,'5':null,'6':null}

  function setAnswer(e) {

    let qlen = document.getElementsByClassName(e.target.className).length
    for (let i=0;i<qlen;i++){
      document.getElementsByClassName(e.target.className)[i].checked = false
    }
    e.target.checked = true;
    answers[e.target.className] = e.target.value
    //add scrolling effect
    if(Number(e.target.className)<document.getElementsByClassName('qcont').length){
      let x = document.createElement('a')
      x.href = '#q'+(Number(e.target.className)+1)
      x.click()
    }

  }





  function checkAnswers() {
    if
    ((answers['1'] !== null) &&
    (answers['2'] !== null) &&
    (answers['3'] !== null) &&
    (answers['4'] !== null) &&
    (answers['5'] !== null) &&
    (answers['6'] !== null))
    {
    console.log(answers)

    document.getElementById('answers').style.display = 'block'

    for (let i=0;i<document.getElementsByClassName('test-answer').length;i++){
      document.getElementsByClassName('test-answer')[i].style.display = 'none'
    }

    /* Maths:
    Muhsroom1:
    A D A B B A
    Muhsroom2:
    B B C A D C
    Muhsroom3:
    C C B D A D
    Muhsroom4:
    D A D C C B
    */

    // SET MUSHROOM ACCORDING TO ANSWERS
      if
      (answers['1'] === 'A')
      {
        document.getElementById('an1').style.display = 'block'
      }
      else if
        (answers['1'] === 'B')
      {
        document.getElementById('an2').style.display = 'block'
      }
      else if
        (answers['1'] === 'C')
      {
        document.getElementById('an3').style.display = 'block'
      }
      else if
       (answers['1'] === 'D')
     {
       document.getElementById('an4').style.display = 'block'
     }

     //SHOW restart button
     document.getElementById('retake').style.display = 'block'

    } else {
      alert('Please select all answers first...')
    }
  }


  function clearTest(){
    for (let i=0;i<document.getElementsByTagName('input').length;i++){
      document.getElementsByTagName('input')[i].checked = false
    }
    answers = {'1':null,'2':null,'3':null,'4':null,'5':null,'6':null}
    document.getElementById('retake').style.display = 'none'

    for (let i=0;i<document.getElementsByClassName('test-answer').length;i++){
      document.getElementsByClassName('test-answer')[i].style.display = 'none'
    }
    document.getElementById('answers').style.display = 'none'

  }



  return (
    <>
    <section id="psychotest" className="hero is-white" style={{backgroundColor:'#E8D3D0',marginTop:'-30px'}}>
    <div className="hero-head has-text-centered" style={{paddingTop:'0px'}}>
    <nav className="tabs is-boxed is-fullwidth">
      <div className="container">
        <ul className="second-menu">
          <li>
            <a href="#q1">1</a>
          </li>
          <li>
            <a href="#q2">2</a>
          </li>
          <li>
            <a href="#q3">3</a>
          </li>
          <li>
            <a href="#q4">4</a>
          </li>
          <li>
            <a href="#q5">5</a>
          </li>
          <li>
            <a href="#q6">6</a>
          </li>
        </ul>
      </div>
    </nav>
    <br />
    <h1 className="title">Psychotest</h1>
    <h2 className="subtitle" style={{maxWidth:'90%',marginLeft:'5%'}}>Answer the questions below to get Your results and find out which mushroom type fits You!</h2>
    </div>

    <br />
    <br />
    <hr id="q1" />
    <br />
    <br />
      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Question 1:<br /> What do you like to do in your free time?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="1" onInput={setAnswer} type="checkbox" name="1A" id="1A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="1A"> a) Stack dominoes with chocolate cubes - it's not only good for the mind, but also delicious!</label>
          </div>

          <div className="control">
          <input  className="1" onInput={setAnswer} type="checkbox" name="1B" id="1B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="1B"> b) Practice jumping over obstacles with a penguin step - because why not?</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="1" onInput={setAnswer} type="checkbox" name="1C" id="1C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="1C">c) Teach sign language to your cat - after all, it's so easy!</label>
          </div>

          <div className="control">
          <input  className="1" onInput={setAnswer} type="checkbox" name="1D" id="1D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="1D">d) Organize meetings with aliens on the roof - if we're already here, why shouldn't we say hello?</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q2"/>
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Question 2:<br /> It's 2 a.m. and your neighbor's party is going strong. What do you do?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="2" onInput={setAnswer} type="checkbox" name="2A" id="2A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="2A"> a) I join the party – after all, it's not every day that an opportunity like this happens!</label>
          </div>

          <div className="control">
          <input  className="2" onInput={setAnswer} type="checkbox" name="2B" id="2B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="2B"> b) I call the cops.</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="2" onInput={setAnswer} type="checkbox" name="2C" id="2C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="2C">c) I leave a passive-aggressive note in the morning, which expresses my anger but does not expose me to direct confrontation. I am careful not to leave traces of my DNA on it.</label>
          </div>

          <div className="control">
          <input  className="2" onInput={setAnswer} type="checkbox" name="2D" id="2D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="2D">d) I pretend that it's just not happening – nothing will disturb my peace of mind. What are earplugs and a pillow for?</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q3" />
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Question 3:<br /> What do you usually eat for breakfast?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="3" onInput={setAnswer} type="checkbox" name="3A" id="3A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="3A">a) A free-range poché egg with fair-trade certified organic avocado on crispy rye toast made with sourdough bread from a local bakery.</label>
          </div>

          <div className="control">
          <input  className="3" onInput={setAnswer} type="checkbox" name="3B" id="3B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="3B">b) Flaxseed oatmeal with cooked fruit – as recommended by my nutritionist.</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="3" onInput={setAnswer} type="checkbox" name="3C" id="3C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="3C">c) Black bread and black coffee.</label>
          </div>

          <div className="control">
          <input  className="3" onInput={setAnswer} type="checkbox" name="3D" id="3D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="3D">d) It doesn't matter, as long as it's filling and tasty. Air and grass will not work.</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q4" />
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Question 4:<br /> What is your style of mushroom picking:</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="4" onInput={setAnswer} type="checkbox" name="4A" id="4A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="4A">a) I buy champignons and oyster mushrooms in the supermarket or at the market, the forest is not for me - you can still get lost and catch a tick.</label>
          </div>

          <div className="control">
          <input  className="4" onInput={setAnswer} type="checkbox" name="4B" id="4B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="4B">b) With the whole family we are in the forest even before the first rays of the sun, we cannot let our secret spot fall into the wrong hands.</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="4" onInput={setAnswer} type="checkbox" name="4C" id="4C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="4C">c) It does not matter if we manage to find something, the important thing is that with friends.</label>
          </div>

          <div className="control">
          <input  className="4" onInput={setAnswer} type="checkbox" name="4D" id="4D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="4D">d) Since the first sight of mycelium on Instagram, I cannot stop growing oyster mushrooms on the window.</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q5" />
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Question 5:<br /> How do you deal with stress?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="5" onInput={setAnswer} type="checkbox" name="5A" id="5A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="5A">a) "I don't deal with stress, it's stress that deals with me". - Woody Allen</label>
          </div>

          <div className="control">
          <input  className="5" onInput={setAnswer} type="checkbox" name="5B" id="5B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="5B">b) "I deal with stress the same way my potted plants deal with it - when I start imposing my expectations on them too much, I just water them down and leave them alone." - Deepak Chopra</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="5" onInput={setAnswer} type="checkbox" name="5C" id="5C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="5C">c) "I deal with stress by arranging my problems in order of importance: from most important to least important - and then ignoring them all" - Timothy Leary</label>
          </div>

          <div className="control">
          <input  className="5" onInput={setAnswer} type="checkbox" name="5D" id="5D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="5D">d) I crumple bubble wrap.</label>
          </div>
        </div>
      </div>
      </div>

      <br />
      <br />
      <hr id="q6" />
      <br />
      <br />

      <div className="hero-body qcont secsection">
      <h3 className="subtitle" style={{textAlign:'center'}}>Question 6:<br /> Which quote about the meaning of life comes most to you?</h3>
      <div className="columns">
        <div className="column">
          <div className="control">
          <input  className="6" onInput={setAnswer} type="checkbox" name="6A" id="6A" value="A"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="6A">a) "The meaning of life consists in constantly opposing its meaninglessness" - Albert Camus</label>
          </div>

          <div className="control">
          <input  className="6" onInput={setAnswer} type="checkbox" name="6B" id="6B" value="B"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="6B">b) "The meaning of life is to find a way to eat and sleep so that we have time to do the things we really like." - Bill Murray</label>
          </div>
        </div>

        <div className="column">
          <div className="control">
          <input  className="6" onInput={setAnswer} type="checkbox" name="6C" id="6C" value="C"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="6C">c) "The meaning of life is like spinning a sausage, it's best to do it slowly and carefully so you don't get twisted" - Tom Robbins</label>
          </div>

          <div className="control">
          <input  className="6" onInput={setAnswer} type="checkbox" name="6D" id="6D" value="D"/>&nbsp;&nbsp;
          <label className='form-text' htmlFor="6D">d) "The meaning of life is the ability to survive in the face of absurd reality." - Frank Zappa</label>
          </div>
        </div>
      </div>
      </div>

      <div style={{textAlign:'center',paddingBottom:'50px'}}>
      <button className="button" onClick={checkAnswers}>Submit answers...</button>
      </div>


      <div className="hero-body" id="answers" style={{display:'none'}}>
      <h2>Your mushroom is:</h2>
        <div className="test-answer" id="an1">
          <h3 className="title">Black Truffle</h3>
          <p>You are like a black truffle – then you must be an exclusive and unique person! You are overflowing with energy and like to be in control of your life, but always have time for your friends and family. You are extremely discerning about your lifestyle and choose only the best things, from clothes to food. You can be hard to find, but when you do, you are memorable because of your unusual and unique character. In any case, a person like you is precious as gold, just like a black truffle!</p>
          <br />
        </div>
        <div className="test-answer" id="an2">
          <h3 className="title">Porcini</h3>
          <p>You are like a porcini, you always find your place and don't let anything distract you. Like a porcini, you are resilient and resistant to the harsh conditions that life throws at you. You are not afraid to show your true nature, just as this mushroom is not afraid to come into the light and expose its distinctive color and shape. You are as real as a real mushroom because you are always yourself and do not pretend to be someone you are not.</p>
          <br />
        </div>
        <div className="test-answer" id="an3">
          <h3 className="title">Oyster mushroom</h3>
          <p>You are like an Oyster mushroom, and your character is like the taste of this mushroom - refreshing and full of energy! You are a cheerful, easy-going person, and your positive approach to life is like vitamin C for the soul. However, just like the Lemon Bacon, sometimes you need a bit of solitude to recuperate and develop your unique personality.</p>
          <br />
        </div>
        <div className="test-answer"  id="an4">
          <h3 className="title">Amanita Muscaria</h3>
          <p>If you are like Amanita Muscaria, you have a character like this mushroom - a bit eccentric as well as full of surprises! You are a creative, full-of-energy person who loves adventures and intellectual challenges. You often do things on your own, you are not afraid to experiment, to reach for the new, and you are able to surprise others with your courage and broad horizons. However, like Amanita Muscaria, you can sometimes be hard to read and you have to be careful not to overshroom you!</p>
          <br />
        </div>
      </div>

      <div id="retake" style={{textAlign:'center',paddingBottom:'50px',display:'none'}}>
      <a href="#q1" className="button" onClick={clearTest}>Retake test...</a>
      </div>

  </section>
    </>
  );
};


export default Psychotest;
